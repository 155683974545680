$(() => {
    const $openFl = $(".js-open");
    const $filterWrap = $(".js-filter__wrap");
	const $modalFilterWrap = $(".js-modal-filter__wrap");
    const $filterClose = $(".js-filter-close");
	const $modalFilterClose = $(".js-modal-filter-close");
    const $form = $(".js-filter-form");
	const $modalForm = $(".js-filter-form");
    const $selects = $filterWrap.find("select");
	const $modalSelects = $modalFilterWrap.find("select");

    $openFl.on("change",  function() {
        const that = $(this)
        const wrapper = that.parents().closest( ".js-filter" )
        if(this.checked) return wrapper.addClass("is_open");
        wrapper.removeClass("is_open");
    })

    $filterClose.on("click", ()=> {        
        $selects.multipleSelect("setSelects", [])
        $selects.next().removeClass("is_fill")
        $form[0].reset()
    })

	$modalFilterClose.on("click", ()=> {        
        $modalSelects.multipleSelect("setSelects", [])
        $modalSelects.next().removeClass("is_fill")
        $modalForm[0].reset()
    })

    $(".js-filter-form").on("submit", function(e) {
        e.preventDefault()
        $selects.multipleSelect('close')
    })
})
