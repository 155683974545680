$(() => {
    const $select = $(".js-multi-select");
    $.each($select, (i, elem) => {
        const select = $(elem)
        initSelect(select)
    })
})

function initSelect(select) {
    const dataName = select.attr("data-name");
    const name = select.attr("name");

    select.multipleSelect({
        minimumCountSelected: 1,
        placeholder: dataName,
        name: name, 
        selectAll: false,
        multiple: true,
        multipleWidth: "100%",
        maxHeight: 200,
        width: "100%",
        onAfterCreate: (e) => {
            setCustomElements(select)
        },
        onOpen: () => {
            select.next().addClass("is_open")
        },
        onClose: () => {
            select.next().removeClass("is_open")
        },
        onClick: () => {
            stylesChange(select)
            
            const refreshItem = select.attr("data-refresh");
            
            if(refreshItem) {
                $(`#${refreshItem}`).multipleSelect('refresh');
            }
        }
    })
}


function setCustomElements (select) {

    const arrowIcon = `
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 16C11.7719 16 11.5449 15.923 11.3599 15.768L5.35991 10.768C4.93591 10.415 4.87791 9.784 5.23191 9.36C5.58491 8.936 6.21491 8.879 6.63991 9.232L12.0109 13.708L17.3729 9.393C17.8029 9.047 18.4329 9.115 18.7789 9.545C19.1249 9.975 19.0569 10.604 18.6269 10.951L12.6269 15.779C12.4439 15.926 12.2219 16 11.9999 16Z"/>
        </svg>

    `
    
    const templateBox = `
        <span class="multiselect__box">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16461 11.25C5.99211 11.25 5.82711 11.1787 5.70899 11.0531L2.66961 7.81624C2.43274 7.56499 2.44586 7.16937 2.69711 6.93312C2.94899 6.69687 3.34461 6.70937 3.58024 6.96062L6.15836 9.70499L11.4134 3.95374C11.6471 3.69812 12.0421 3.68124 12.2971 3.91374C12.5515 4.14624 12.569 4.54187 12.3365 4.79624L6.62586 11.0462C6.50899 11.175 6.34274 11.2487 6.16899 11.25H6.16461Z" fill="white"/>
            </svg>
        </span>
    ` 
    
    select.next().find(".icon-caret").html(arrowIcon)

    const label = select.next().find("label")
    label.parent().addClass("multiselect__item");
    label.addClass("multiselect__label")
    label.find("input").after($(templateBox))

    stylesChange(select)
}

function stylesChange (select) {
    const name = select.attr("data-name")
    const options= select.multipleSelect("getSelects")
    const optionsLength = options.length

    if(optionsLength === 0) select.next().removeClass("is_fill")
    if(optionsLength > 0) select.next().addClass("is_fill")
    if(optionsLength > 1) select.next().find(".ms-choice > span").text(`${name} + ${optionsLength}`)
} 