$(() => {
    const $stateGrid = $(".js-state-grid");
    const $stateRow = $(".js-state-row");

    const initLocalState = localState();

    if (initLocalState) {
        $stateRow.parent().addClass(initLocalState)
        const $productItem = $(".catalog .js-product-item");
        $productItem.addClass(initLocalState)
        $(".js-product-title").elementHeight("isSingle");
    }

    $stateGrid.on("click", function() {
        $(this).parent().removeClass("is_row");
        const $productItem = $(".catalog .js-product-item");
        $productItem.removeClass("is_row");

        localStorage.removeItem("is-row");
        $(".js-product-title").elementHeight("reInit");
        $(".js-products-props").elementHeight("reInit");
    })

    $stateRow.on("click", function() {
        $(this).parent().addClass("is_row");
        const $productItem = $(".catalog .js-product-item");
        $productItem.addClass("is_row");
        localStorage.setItem("is-row", true);

        $(".js-product-title").elementHeight("isSingle");
        $(".js-products-props").elementHeight("isSingle");
    })

    $(window).on('resize', function(){
        const $productItem = $(".catalog .js-product-item");
		if($(window).width()<=900 && $productItem.hasClass('is_row')) {
            localStorage.removeItem("is-row");
            $productItem.removeClass("is_row");
            $('.state-mode__inner').removeClass("is_row");
        }
	});
})

function localState() {
    return localStorage.getItem("is-row") ? "is_row" : ""
}

